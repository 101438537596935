@import '/src/utility/baseStyles.scss';
.adv{
    position: relative;

&__title{
    text-align: center;
    margin-bottom: 78px;    
}
&__wrap{
    display: flex;
    flex-wrap: wrap;}
&__boxer{
    display: flex;
    flex-direction: column;
    width: 100%;
}
&__item{
    width: 50%;
    display: flex;
    margin-bottom: 30px;
img{
    margin-bottom: 35px;
    margin-right: 30px;
}    
&__text{
    display: flex;
    width: 100%;
    flex-direction: column;
    &__title{
        font-size: 24px;
        font-family: $bold-font;
        margin: 0;
        margin-bottom: 11px;
    }
    &__subtitle{
        font-size: 20px;
        font-family: $reg-font;
    }
}}}
@media(max-width: 991px){
    .adv{
        &__wrap{
            align-items: center;
            flex-direction: column;}
        &__text{
                font-size: 17px;
        }
        &__item{
            width: 100%;
            margin-bottom: 0;
            &__img {
                width: 25%;}}
    }
}
@media(max-width: 480px){
    .adv__item__text__title{
        font-family: $bold-font;
        font-size: 18px;
    }
    .adv__item__text__subtitle {
        font-size: 14px;}
    .adv__item img {
        width: 75%;}
}