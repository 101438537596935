@import '/src/utility/baseStyles.scss';
.cars{
    font-family: $reg-font;
    margin-top: 30px;
    &__wrap{
        display: flex;
        align-items: baseline;
        width: 100%;
        justify-content: space-between;}
        &__box{
            width: 11%;
            position: relative;
            text-align: center;
        &_one{
            width: 11%;
            text-align: center; 
            img{
                max-width: 100%;
                margin: 0;
            }
        }
        &:after{
            top: 14%;
            left: 112%;
            position: absolute;
            content: url('/src/img/adv/line.svg');
        }
        &__content{
            text-align: center;
            width: 50%;
            display: inline;
        &__text{
            font-size: 19px;

        strong{
            display: block;
        }}}}
    
&__logo{
    max-width: 100%;
}}
@media(max-width: 991px){
    .cars{
        &__wrap{
            align-items: baseline;
            width: 100%;
            justify-content: space-evenly;
            flex-wrap: wrap;}
        &__box{
            position: relative;
            width: 50%;
            text-align: center;
            height: 150px;
         &__content{
        width: 30%;
        margin: 0 auto;
    }
            &:after{
                display: none;
            }
        &_one{
            position: relative;
            width: 50%;
            text-align: center;
        }
        &__content{
            &__text{
            font-family: $reg-font;
            font-size: 16px;
            margin: 0 auto;
            }
            }}
    }
}