@import '/src/utility/baseStyles.scss';

.footer {
    margin: 74px 25px 45px;
    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: baseline;}

        &__contacts {
            font-size: 18px;
            font-family: $reg-font;
            display: flex;
            width: 41%;
            justify-content: space-between;
            
            &__street,
            &__creaters {
                 margin: 0;
    }
            
        }

        &__links {
            font-family: $reg-font;
            position: relative;
            display: flex;
            width: 15%;
            justify-content: space-around;
            align-items: baseline;

            &__inst,
            &__phone {
                color: $second-color;
                text-decoration: none;
            }

            &__phone {
                font-size: 20px;
            }
           

           
            
        }
        &__inst{
            color: $second-color;
            text-decoration: none;
            &:before{
                width: 31px;
                height: 28px;
                content: url("/src/img/footer/instagram.svg");
                position: absolute;
                top: -139%;
                left: 40%;
        }}

    &__ico{
        vertical-align: bottom;
    }
&__call{
    display: flex;
    justify-content: space-evenly;
    width: 34%;
    align-items: baseline;
    
}
&__order{
    color: $second-color;
    text-decoration: none;
}
&__phone{
    text-decoration: none;
    color: $second-color;
}
&__inst,
&__call,
&__street,
&__creaters {
    font-size: 18px;
}}
    @media(max-width: 1199px){
        .footer{

                &__contacts{
                    width: 47%
                }
            &__links{
                width: 15%;
                &:before{
                    left: 12%;
                }
                &__inst{
                    &:before{
                        top: -168%;
                        left: 38%;
                    }
                   }
            &__phone{
                font-size: 15px;
            }}
            .footer__inst, .footer__call, .footer__street, .footer__creaters {
                font-size: 18px;
            }}
    }
    @media(max-width: 1024px){
        .footer{            
            margin: 0;
            margin-bottom: 30px;

            &__wrap{
                justify-content: center;
                flex-direction: column-reverse;
                width: 100%;
                height: 100%;}
            &__contacts{
                width: 100%;
                flex-direction: column-reverse;
                align-items: center;
            }
            &__links{
                width: 100%;
                flex-direction: column-reverse;
                align-items: center;
            
           &__phone{
               font-size: 18px;
               margin-bottom: 10px;
               
           }
            &__inst{
                margin-top: 8px;
            &:before{
                    
                    margin: 0 auto;
                   
                 margin-bottom: 10px;
                left: 0;
                position: relative;
                display: block;
                text-align: center;}}}

        &__ico{
            display: none;}
        &__call{
                justify-content: center;
                width: 100%;
                flex-direction: column;
                align-items: center;
                margin-bottom: 47px;
                
           
        }
    &__street, &__creaters{
        margin: 0;
    }
&__phone{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: $second-color;
    text-decoration: none;
    position: relative;
        &:before{
            content: url("/src/img/footer/Vector.svg");
            position: absolute;
            left: -25%;
            top: -24%;
        }
}&__order{
    font-size: 18px;
    margin-bottom: 10px;
}
&__creaters{
    margin-top: 16px;
    margin-bottom: 10px;
}
&__inst{
    &:before{
        left: 48%;
    }
}}
    }
    @media(max-width: 768px){
        .footer{
            &__inst{
                &:before{
                        left: 47%;
                }

                &__links{
                   
                    }
                    &__phone{
                        &:before{
                            left: 32%;
                        }
                    }
                }

        }
    }

   
    @media(max-width: 480px){
        .footer{
                &__inst{
                    &:before{
                        left: 45%;
                    }
                }

                &__links{
                    &__phone{
                        &:before{
                            left: 31%;
                            top: -3%;
                        }
                    }
                }

        }
    }
    @media(max-width: 420px){
        .footer{
            &__links{
                &__phone{
                    &:before{
                            left: 28%;
                    }
                }
            }
        }
    }
    @media(max-width: 380px){
        .footer{
                &__inst{
                    &:before{
                            left: 42%;
                            top: -180%;
                            }
                    }
                    
                &__links{
                    &__phone{
                        &:before{
                            left: 25%;
                            
                        }
                    }
                }

        }
    }
    @media(max-width: 320px){
        .footer{
                &__inst{
                    &:before{
                        left: 42%;
                        top: none;
                    }
                }
                &__links{
                    &__phone{
                        &:before{
                            left: 21%;
                            
                        }
                    }
                }
        }
    }
