html{
    box-sizing: border-box;
}
section{
    padding: 60px 0px;
}
.text{
    font-size: 17px;
    font-family: 'Roboto-Regular';
}
.subtext{
    font-family: 'Roboto-Bold';
}
.title{
    font-weight: 500;
    font-size: 40px;
    font-family: 'Roboto-Bold';
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 0;
    margin-bottom: 80px;
    &:before{
        content: "";
        background-color: #F8EBAB;
        width: 68px;
        border-radius: 50%;
        height: 68px;
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        left: 22%;
        z-index: -1;
}}
// .adv__title{
//     &:before{
//         content: '';
//         background-color: #F8EBAB;
//         width: 68px;
//         border-radius: 50%;
//         height: 68px;
//         position: absolute;
//         z-index: -1;
//         top: 21%;
//         left: 21%;
//         z-index: -1; 
// }
// }
    .discound__title{
//     padding-top: 14px;
//     margin-bottom: 50px;
    &:before{
//         content: '';
//         background-color: #F8EBAB;
//         width: 68px;
//         border-radius: 50%;
//         height: 68px;
//         position: absolute;
//         z-index: -1;
        top: 45%;
        left: 30%;
        z-index: -1; 
}
}
.solution__title{
    // padding-top: 60px;
    // margin-bottom: 115px;

    &:before{
        content: '';
        // background-color: #F8EBAB;
        // width: 68px;
        // border-radius: 50%;
        // height: 68px;
        // position: absolute;
        // z-index: -1;
        top: 53%;
        left: 24.4%;
        z-index: -1; 
}
}
.photo__title{
    &:before{
        // content: '';
        // background-color: #F8EBAB;
        // width: 68px;
        // border-radius: 50%;
        // height: 68px;
        // position: absolute;
        // z-index: -1;
        top: 41%;
        left: 40%;
        z-index: -1; 
}
}

@media(max-width: 1199px){
    .adv__title{
        &:before{
            left: 14.5%;
            top: 51%;
        }
    }
    .discound__title{
        &:before{
            left: 25%;
        }
    }
    .solution__title{
        &:before{
            top: 48%;
            left: 19.5%;
        }
    }
    .photo__title{
        &:before{
            left: 38%;
        }
    }
}
@media(max-width: 991px){
    section{
            padding: 30px 0px;
    }
    .title{
        font-size: 28px;
        margin-bottom: 40px;
        &:before{
            width: 50px; 
            height: 50px;

    }}
    .adv__title{
        font-size: 28px;
        &:before{
            left: 16%;
        }
    }
    .discound__title{
        &:before{
            left: 25%;
        }
    }
    .solution__title{
        &:before{
            left: 19.3%;
        }
    }
    .photo__title{
        &:before{
            left: 37.4%;
            top: 50%;
        }
    }
}
@media(max-width: 768px){
    .adv__title{
        &:before{
            left: 16.3%;
            top: 43%;
        }
    }
    .discound__title{
        &:before{
            left: 25%;
        }
    }
    .solution__title{
        &:before{
            left: 19.5%;
            top: 45%;

        }
    }
    .photo__title{
        &:before{
            left: 37.4%;
        }
    }
}
@media(max-width: 576px){
    .adv__title{
        &:before{
            left: 4%;
            top: 44%;
          
        }
    }
    .discound__title{
        &:before{
            left: 16%;

        }
    }
    .solution__title{
        &:before{
            left: 9%;
            top: 45%;
        }
    }
    .photo__title{
        &:before{
            left: 33%;
        }
    }
}
@media(max-width: 480px){
    .adv__title{
        &:before{
            left: -2%;
            top: 45%;
        
        }
    }
    .discound__title{
        &:before{
            top: 45%;
            left: 12%;

        }
    }
    .solution__title{
        &:before{
            left: 3.7%;
            top: 43%;
        
        }
    }
    .photo__title{
        &:before{
            left: 31%;
        }
    }
}
@media(max-width: 415px){
    .adv__title{
        &:before{
            left: 8%;
            top: 21%;
            height: 50px;
            width: 50px;      
        }
    }
    .discound__title{
        &:before{
            top: 42%;
            left: 6%;        
            height: 50px;
            width: 50px;

        }
    }
    .solution__title{
        &:before{
            left: 5%;
            top: 23%;
            height: 50px;
            width: 50px;
        }
    }
    .photo__title{
        &:before{
            left: 27%;
            height: 50px;
            width: 50px;
            top: 44%;
        }
    }
}
@media(max-width: 380px){
    .title{
        text-align: left;
    }
    .adv__title{
        &:before{
            left: -2%;
            top: 21%;
            height: 50px;
            width: 50px;      
        }
    }
    .discound__title{
        &:before{
            top: 47%;
            left: 0%;
            height: 50px;
            width: 50px;

        }
    }
    .solution__title{
        &:before{
            left: 0%;
            top: 22%;
            height: 50px;
            width: 50px;
        }
    }
    .photo__title{
        &:before{
            left: 0%;
            height: 50px;
            width: 50px;
            top: 44%;
        }
    }
}
@media(max-width: 320px){
    .adv__title{
        &:before{
            left: -2%;
            top: 21%;
            height: 50px;
            width: 50px;      
        }
    }
    .discound__title{
        &:before{
            top: 25%;
            left: 0%;
            height: 50px;
            width: 50px;

        }
    }
    .solution__title{
        &:before{
            left: 0%;
            top: 23%;
            height: 50px;
            width: 50px;
        }
    }
}


$main-color:#112F91;
$second-color: #000;
$reg-font:'Roboto-Regular';
$bold-font:'Roboto-Bold';

@font-face {
    font-family: 'Roboto-Regular';
    src: url('/src/fonts/Roboto_reg/Roboto-Regular.eot');
    src: url('/src/fonts/Roboto_reg/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/Roboto_reg/Roboto-Regular.woff') format('woff'),
    url('/src/fonts/Roboto_reg/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }

@font-face {
    font-family: 'Roboto-Bold';
    src: url('/src/fonts/Roboto_bold/Roboto-Bold.eot');
    src: url('/src/fonts/Roboto_bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/Roboto_bold/Roboto-Bold.woff') format('woff'),
    url('/src/fonts/Roboto_bold/Roboto-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }