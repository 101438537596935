@import '/src/utility/baseStyles.scss';

.header {
    position: relative;
    z-index: 14;

    /*Полная версия*/
    &__links {
        display: flex;
        width: 72.8%;
        justify-content: space-between;

        &__item {
            color: $second-color;
            text-decoration: none;

            &:hover {
                color: grey;
            }
        }
    }



    &__phone {
        width: 20%;
        color: $second-color;
        text-decoration: none;
        font-size: 24px;

        img {
            vertical-align: bottom;
        }

    }

    &__wrap {
        width: 100%;
        display: flex;
        align-items: baseline;
        align-items: center;
        justify-content: space-between;
        height: 82px;
    }

    /*Мобильная версия*/
    &__mobile {
        display: none;

        &__logo {

            display: flex;
            align-items: center;
            z-index: 0;
        }

        &__wrap {
            align-items: center;
            height: 70px;
            display: flex;
            justify-content: space-between;
            z-index: 20;}
    
            &__burger {
                width: 48px;
                background-color: $main-color;
                position: relative;
                color: $second-color;
                text-align: center;
                height: 48px;
                border-radius: 50%;
                cursor: pointer;

                &__line {
                    position: absolute;
                    right: 9px;
                    width: 30px;
                    height: 2px;
                    background-color: #F8EBAB;

                    &_one {
                        top: 15px;
                    }
                    &_one_rotate{
                        top: 22px;
                        transform: rotate(40deg);
                        transition: ease;
                        transition-duration: 0.5s;
                    }

                    &_two {
                        top: 23px;
                    }
                    &_two_rotate{
                        display: none;
                    }

                    &_three {
                        top: 31px;
                    }
                    &_three_rotate{
                        top: 22px;
                        transform: rotate(-40deg);
                        transition: ease;
                        transition-duration: 0.5s;
                    }
                }
            }

    &__popup {
        top: -999px;
        position: absolute;
        width: 100%;
        height: max-content;
        background-color: #fff;
        text-align: center;
        box-shadow: 7px 39px 58px -51px rgba(0,0,0,.75)}
        &__links {
            width: 100%;
            display: flex;
            flex-direction: column;

            &__item {
                color: $second-color;
                text-decoration: none;
                padding: 17px 0px;
                border-bottom: 2px solid #F7F6F7;
            }
            
        }

        &__contact {
            padding: 17px 0px;

            &__logo {
                height: 30px;
            }

            &__phone {
                color: $second-color;
                text-decoration: none;
                display: inline;
                text-align: center;
                font-size: 21px;
                font-family: $bold-font;
            }
        }

        &__cross__logo {
            height: 26px;
            position: absolute;
            right: 3%;
            top: 0%;
            cursor: pointer;
        }

        &__show {
            top: 100%;
            left: 0;
            right: 0;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            z-index: -4;
        }

        &__hide {
            top: -999px;
            left: 0;
            right: 0;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
        }
    }
}
.mobile-bg{
    background-color: #fff !important;
}


@media(max-width: 1399px){
    .header{
        &__phone{
            font-size: 17px;
        }
    }
}
@media(max-width: 1199px) {
    .header {
        &__desktop {
            &__wrap {
                justify-content: space-between;
            }

            &__links {
                &__item {
                    margin-right: 20px;
                }
            }
        }
    }
}

@media(max-width: 1024px) {
    .header {
        &__links {
            &__item {
                font-size: 13px !important;
            }

        }

        &__phone {
            font-size: 17px;
        }
    }
}

@media(max-width: 991px) {
    .header {
        &__desktop {
            display: none;
        }

        &__mobile {
            width: 100%;
            position: fixed;
            background-color: #F7F6F7;
            display: flex;
        }
    }
}