@import '/src/utility/baseStyles.scss';
.solution{
    position: relative;
    background: #fff;
    margin: 0px 346px;
    padding-left: 15px;
    padding-right: 15px;
    padding: 25px;
&__wrap{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
.img-wrap{
    margin-bottom: 27px;
    width: 100%;
}}

    &__box{
        position: relative;
        width: 31%;
        text-align: center;
        margin-bottom: 111px;
        padding: 4px;
        &:before{
            top: 44%;
            left: 73%;
            position: absolute;
            content: url('/src/img/solution/line.svg');
        }
        &:nth-child(3){
            &::before{
                content: '';
            }
            margin-bottom: 0px;
        }
        &:nth-child(4){
            margin-bottom: 0px;
        }
        &:nth-child(5){
            margin-bottom: 0px;
        }
        &:nth-child(6){
            &::before{
                content: '';
            }
            margin-bottom: 0px;
        }
    &__content{
        width: 100%;
    }
&__circle{
    font-family: $bold-font;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    border: 6px solid #F6EF98;
    background-color: #fff;
    padding: 6px;
    font-size: 26px;
}}}
@media(max-width: 1800px){
    .solution{
        margin: 0px 16px;
    }
}
@media(max-width: 1199px){
    .solution{
        margin: 0px 60px;
    }
}
@media(max-width: 1024px){
    .solution{
    margin: 0px 10px;}

}

@media(max-width: 991px){
    
    .solution{
            margin: 0px 0px;
            padding-left: 0;
            padding-right: 0;
        &__wrap{
            flex-direction: column;}
        &__box{
            padding: 20px;
            width: 100%;
            margin-bottom: 18px;
            position: relative;
            &:before{
                content: "";
            }
            &:nth-child(2){
                background-color: #F7F6F7;
            }
            &:nth-child(4){
                background-color: #F7F6F7;
            }
            &:nth-child(6){
                background-color: #F7F6F7;
            }
            
            &__circle{
                position: absolute;
                font-family: $bold-font;
                height: 52px;
                width: 52px;
                border-radius: 50%;
                border: 6px solid #F6EF98;
                background-color: #fff;
                padding: 10px;
                font-size: 18px;
                margin: 10px;
            }
            &__grey{
                background-color: #F7F6F7;
            }
            &_three, &_six{
                width: 100%;
                margin: 0;
                margin-bottom: 18px;
            }
        &:after{
            display: none;
        }
        &__circle{
            left: 0%;
            top: 0%;
        }}
    }
}
@media (max-width: 480px){
.solution__box__subtitle {
    font-size: 19px;
}
}
