@import '/src/utility/baseStyles.scss';
.discound{
    padding: 30px 0;
    font-family: $reg-font;
    background: #F7F6F7;
    height: 734px;
    &__wrap{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;}
        &__box{
            margin: 0px;
            position: relative;
            width: 28.7%;
            text-align: center;
         &__text{
            line-height: 25px;
            font-weight: 300;
            font-size: 17px;
            margin: 0;
            margin-top: 25px;
            font-family: $reg-font;
        }
       
        &__logo{
            margin-top: 46px;
            max-width: 100%;
        }
        &__title{
            margin: 0;
            font-size: 29px;
            line-height: 28px;
            font-family: $reg-font;
            font-weight: 500;
       }
       &__subtitle{
           font-family: $reg-font;
            font-weight: 500;
            line-height: 28px;
            margin: 0;
            font-size: 21px;
       span{
               display: block;
       }}
        &__discound{
            font-family: $bold-font;
            position: absolute;
            margin: 0;
            z-index: 20;
            color: #112F91;
            font-size: 64px;
            top: 43%;
            left: 30%;
        }}
&__inst__link, &__tel__link{
    color: #000;
    text-decoration: none;
}}
@media(max-width: 991px){
    .discound{
        padding: 33px 0px;
        height: 100%;
        &__wrap{
            flex-direction: column;
            justify-content: none;
            margin-top: 27px;}
        
        &__box{
            width: 100%;
            margin-bottom: 35px;
        &__logo{
            margin-top: 18px;
        }
        &__discound{
            left: 38%;
        }}

    }
}
@media(max-width:480px){
    .discound{
        &__box{
        &__subtitle{
            font-size: 13px;
        }
        &__discound{
            left: 33%;
        }
    &__title{
        font-size: 20px;
    }
    &__subtitle{
        font-size: 20px;
    }

    &__logo{
        width: 60%;
    }}

    }

}