@import '/src/utility/baseStyles.scss';
.main{

    background-image: url('/src/img/main/здания.png');
    background-size: cover;
    position: relative;
    height: 623.1px;

    &__wrap{
        display: flex;
        width: 100%;
        height: 608px;
        align-items: center;    
    }
    &__info{
        justify-content: center;
        width: 58%;
    }
    &__title{
        display: inline-block;
        font-family: $bold-font;
        font-size: 48px;
        margin-bottom: 26px;
        margin-top: 0px;
        
    }
    &__subtitle{
        display: inline-block;
        width: 80%;
        font-family: $reg-font;
        font-size: 28px;
        margin: 0;
        margin-top: 0;
        margin-bottom: 38px;
    }
    &__price{
        font-size: 40px;
        margin: 0;
        margin-bottom: 36px;
        margin-top: 0;
    }
    &__btn{
        font-family: $reg-font;
        display: inline-block;
        text-decoration: none;
        outline: none;
        font-size: 19px;
        background-color: #112F91;
        color: #fff;
        border: none;
        padding: 17px 54px;
        border-radius: 50px;
    }
&__box{
    position: absolute;
    left: 46.6%;
    top: 46%;
    

}
&__logo{
    width: 100%
}}


   
.button:hover{
   box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.38)
}
.button.cancel{
   background-color: tomato;
   color: #fff;
  } 
.button.ok{
   background-color: #36CC36;
   color: #fff;
  } 
.ripple-effect{ 
   position: relative;
   overflow: hidden;
 }  
.ripple{
   position: absolute;
   width: 10px ;
   height: 10px;
   border-radius: 5px;
   background-color: #fff;
   opacity: 0;
   transform: translate(-50%, -50%);
 }  
.ripple.animate{
   animation: ripple 2s;
 }  
@keyframes ripple{
   from{
      opacity: 0.25;}
   to{
      transform: scale(50);
      opacity: 0.0;}
}   
  

@media(max-width: 991px){
    .main{
        height: 100%;
        background-color: #F7F6F7;
        background-image: none;
        padding-top: 70px;
        padding-bottom: 40px;
        &__wrap{
            height: 100%;
        }
        &__title{
            font-size: 30px;
            font-family: $bold-font;
            margin-top: 7px;
            margin-bottom: 12px;
        }
        &__subtitle{
            margin-bottom: 17px;
            font-size: 18px;
            font-family: $reg-font;
        }
        &__price{
            font-size: 30px;
            font-family: $bold-font;
        }
        &__info{
            width: 100%;
            text-align: center;
        }
        &__box{
            display: none;
        }
    }
}
@media(max-width: 1024px){
    .main__subtitle{
        width: 79%;
    }
    .main__box {
        left: 41.8%;
        top: 31.4%;
    }
}