.photo{
    position: relative;
    &__wrap{
        display: flex;
        flex-flow: wrap;}

    &__mobile{
        display: none;
    }
    &__item{
        width: 33%;
        margin-left: 0;
        z-index: 0;
        &:hover {
            z-index: 3;
        }
         &:nth-child(3){
             margin-right: 0;
         }
         &:nth-child(6){
            margin-right: 0;
        }    
        }
    &__img{
        width: 100%;
        height: 100%;
        filter: brightness(50%);
        
       
        &__scale {
            transition: 1s;

            &:hover {
                width: 100%;
                z-index: 11;
                filter: none;
                transform: scale(1.1);
                cursor: pointer;}}
          }}

.prev{
    display: none;
    right: 17%;
    position: absolute;
    top: -14%;
    height: 60px;
    width: 60px;
    z-index: 1;
    cursor: pointer;
    z-index: 4;
}
.next {
    display: none;
    position: absolute;
    top: -14%;
    right: 0;
    height: 60px;
    width: 60px;
    z-index: 1;
    cursor: pointer;
    z-index: 4;
  }
  @media(max-width:1024px){
    .photo__item {
        width: 31%;
        margin: 8px;
    
  }
}
  @media(max-width: 991px){
    .photo{
        &__wrap{
            display: none;}
           
            
            &__mobile{
        display: block;
        &__img{
            width: 100%;
            filter: brightness(100%)
        }
        
    }
   
        
    }
    .next, .prev{
        display: block;
    }
    
  }
  @media(max-width: 768px){
      .photo__wrap__img {
        width: 97%;}
      .next, .prev{
        height: 30px;
        width: 30px; 
      }
  }
  @media(max-width: 320px){
    
    .next, .prev{
        top: -17%;
      height: 20px;
      width: 20px; 
    }
}
@media(max-width: 1199px){
    .photo__wrap__item {
        width: 32%;
        margin: 7px;
}
}